// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-landing-js": () => import("/opt/build/repo/src/templates/landing.js" /* webpackChunkName: "component---src-templates-landing-js" */),
  "component---src-templates-brand-assets-js": () => import("/opt/build/repo/src/templates/brand-assets.js" /* webpackChunkName: "component---src-templates-brand-assets-js" */),
  "component---src-templates-about-js": () => import("/opt/build/repo/src/templates/about.js" /* webpackChunkName: "component---src-templates-about-js" */),
  "component---src-templates-contact-js": () => import("/opt/build/repo/src/templates/contact.js" /* webpackChunkName: "component---src-templates-contact-js" */),
  "component---src-templates-articles-js": () => import("/opt/build/repo/src/templates/articles.js" /* webpackChunkName: "component---src-templates-articles-js" */),
  "component---src-templates-resources-js": () => import("/opt/build/repo/src/templates/resources.js" /* webpackChunkName: "component---src-templates-resources-js" */),
  "component---src-templates-media-js": () => import("/opt/build/repo/src/templates/media.js" /* webpackChunkName: "component---src-templates-media-js" */),
  "component---src-templates-page-js": () => import("/opt/build/repo/src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-support-js": () => import("/opt/build/repo/src/templates/support.js" /* webpackChunkName: "component---src-templates-support-js" */),
  "component---src-templates-buy-js": () => import("/opt/build/repo/src/templates/buy.js" /* webpackChunkName: "component---src-templates-buy-js" */),
  "component---src-templates-products-js": () => import("/opt/build/repo/src/templates/products.js" /* webpackChunkName: "component---src-templates-products-js" */),
  "component---src-templates-article-js": () => import("/opt/build/repo/src/templates/article.js" /* webpackChunkName: "component---src-templates-article-js" */),
  "component---src-templates-product-line-js": () => import("/opt/build/repo/src/templates/productLine.js" /* webpackChunkName: "component---src-templates-product-line-js" */),
  "component---src-templates-product-series-js": () => import("/opt/build/repo/src/templates/productSeries.js" /* webpackChunkName: "component---src-templates-product-series-js" */),
  "component---src-templates-resource-js": () => import("/opt/build/repo/src/templates/resource.js" /* webpackChunkName: "component---src-templates-resource-js" */),
  "component---src-templates-download-js": () => import("/opt/build/repo/src/templates/download.js" /* webpackChunkName: "component---src-templates-download-js" */),
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-account-profile-js": () => import("/opt/build/repo/src/pages/account/profile.js" /* webpackChunkName: "component---src-pages-account-profile-js" */),
  "component---src-pages-callback-js": () => import("/opt/build/repo/src/pages/callback.js" /* webpackChunkName: "component---src-pages-callback-js" */),
  "component---src-pages-company-about-us-js": () => import("/opt/build/repo/src/pages/company/about-us.js" /* webpackChunkName: "component---src-pages-company-about-us-js" */),
  "component---src-pages-company-career-js": () => import("/opt/build/repo/src/pages/company/career.js" /* webpackChunkName: "component---src-pages-company-career-js" */),
  "component---src-pages-company-forms-general-js": () => import("/opt/build/repo/src/pages/company/forms/general.js" /* webpackChunkName: "component---src-pages-company-forms-general-js" */),
  "component---src-pages-company-forms-pr-js": () => import("/opt/build/repo/src/pages/company/forms/pr.js" /* webpackChunkName: "component---src-pages-company-forms-pr-js" */),
  "component---src-pages-company-forms-resellers-js": () => import("/opt/build/repo/src/pages/company/forms/resellers.js" /* webpackChunkName: "component---src-pages-company-forms-resellers-js" */),
  "component---src-pages-company-forms-rma-js": () => import("/opt/build/repo/src/pages/company/forms/rma.js" /* webpackChunkName: "component---src-pages-company-forms-rma-js" */),
  "component---src-pages-company-forms-sales-js": () => import("/opt/build/repo/src/pages/company/forms/sales.js" /* webpackChunkName: "component---src-pages-company-forms-sales-js" */),
  "component---src-pages-company-forms-support-js": () => import("/opt/build/repo/src/pages/company/forms/support.js" /* webpackChunkName: "component---src-pages-company-forms-support-js" */),
  "component---src-pages-media-articles-js": () => import("/opt/build/repo/src/pages/media/articles.js" /* webpackChunkName: "component---src-pages-media-articles-js" */),
  "component---src-pages-news-info-php-js": () => import("/opt/build/repo/src/pages/news-info.php.js" /* webpackChunkName: "component---src-pages-news-info-php-js" */),
  "component---src-pages-news-php-js": () => import("/opt/build/repo/src/pages/news.php.js" /* webpackChunkName: "component---src-pages-news-php-js" */),
  "component---src-pages-product-info-php-js": () => import("/opt/build/repo/src/pages/product-info.php.js" /* webpackChunkName: "component---src-pages-product-info-php-js" */),
  "component---src-pages-product-php-js": () => import("/opt/build/repo/src/pages/product.php.js" /* webpackChunkName: "component---src-pages-product-php-js" */),
  "component---src-pages-search-js": () => import("/opt/build/repo/src/pages/search.js" /* webpackChunkName: "component---src-pages-search-js" */),
  "component---src-pages-unauthorized-js": () => import("/opt/build/repo/src/pages/unauthorized.js" /* webpackChunkName: "component---src-pages-unauthorized-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/opt/build/repo/.cache/data.json")

